<template>
  <!-- 活动中心 -->
  <div class="activityCont">
    <div class="nav">
      <nav-bar title="提现记录" left-text left-arrow @click-left="goBack" />
    </div>
    <div class="convert-record">
      <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="getRecord()">
        <div class="list-item" v-for="(item, index) in list_arr" :key="index">
          <div class="list-item-con">
            <div class="list-item-left">
              <div class="auth-img">
                <img :src="item.headimgurl ? item.headimgurl:auth" alt="">
              </div>
              <div class="auth-info">
                <span>费率: {{item.fee}}</span>
                <span>金额: {{item.apply_amount}}</span>
              </div>
            </div>
            <div class="list-item-left" style="margin-right:10px">
              <div class="auth-info—2">
                <span>状态: {{ item.status | now_status }}</span>
                <span>提现方式: {{item.account_type | type }}</span>
              </div>
            </div>
          </div>
          <div class="bot-text" v-if="item.status == 2">
            <span>驳回原因: {{ item.refuse_reason }}</span>
          </div>
          <div class="bot-text" v-if="item.status == 1">
            <span>支付状态: {{ item.is_payment | now_is_payment}}</span>
          </div>
          <div class="bot-text" v-if="item.is_payment == 1">
            <span>到账金额: {{ item.amount }}</span>
          </div>
          <div class="bot-text" v-if="item.is_payment == 1">
            <span>支付时间: {{ item.payment_time | filterTime }}</span>
          </div>
          
        </div>
      </List>
    </div>
  </div>
</template>

<script>
import User from "@/api/user";
import { navBar, List } from "vant";
export default {
  components: {
    navBar,
    List,
  },
  filters: {
    // 现方式 1余额 2微信钱包 3支付宝 4银行卡
    type: function(val) {
      switch (val) {
        case 1:
          return "余额";
        case 2:
          return "微信钱包 ";        
        case 3:
          return "支付宝 ";        
        case 4:
          return "银行卡";      
        default:
          break;
      }
    },
    // 状态 0审核中 1审核通过 2审核驳回
    now_status: function(val) {
      switch (val) {
        case 0:
          return "审核中";
        case 1:
          return "审核通过 ";        
        case 2:
          return "审核驳回 ";        
        default:
          break;
      }
    },
    now_is_payment: function(val) {
      switch (val) {
        case 0:
          return "未打款";
        case 1:
          return "已打款";        
        case 2:
          return "打款失败";        
        default:
          break;
      }
    }
  },
  data() {
    return {
      page: 0,
      list_arr: [],
      loading: true,
      finished: false,
      auth: require('../../../../../../public/static/images/vip/touxiang_1.png')
    };
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    async getRecord() {
      try {
        const res = await User.getWithdrawal(++this.page);
        if (res.data.total > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let el = res.data.data[i];
            this.list_arr.push(el);
          }
         this.loading = false;
         if (res.data.total == this.list_arr.length) this.finished = true, this.loading = false;
        } else {
          this.finished = true;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.activityCont {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.list-item {
  width: 96%;
  height: auto;
  border-radius: 6px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.list-item-left {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.auth-img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 50%;
  background-color:bisque;
}
.auth-img img {
  width: 100%;
  height: 100%;
}
.list-item-con {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fdfdfd;
}
.auth-info, .auth-info—2 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 20px 0;
  margin-left: 10px;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;
}


.auth-info span:nth-child(1) {
  font-size: 16px;
  color: #363636;
}
.auth-info span:nth-child(2) {
  font-size: 12px;
  color: #696969;
}
.item-right {
  flex: 1;
  padding: 20px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.bot-text {
  width: 100%;
  height: 30px;
  padding-left: 20px;
  line-height: 30px;
}
</style>